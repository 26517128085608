/* Base styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.home, .upload, .checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
  text-align: center;
  box-sizing: border-box;

  max-width: 500px;
  margin: 0 auto;
}



.home, .upload {
  overflow: hidden;
  justify-content: center;
}