
.home {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    max-width: 500px;
    margin: 0 auto;
  }

.home .tagline {
    color: white;
    font-size: 1.2em;
    margin: 0;
    margin-bottom: 24px;
}


.home .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    max-height: 100%;
}

.home .bottom {
    /* overlays the video, stick at the bottom and black gradient towards video on top */
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.home .bottom, .home .bg-video {
    max-width: 500px;
    /* center the absolutely positioned content */
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
}

.home a {
    width: 100%;
    text-align: center;
    display: block;
    text-decoration: none;
}
