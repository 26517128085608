.container {
    padding: 20px;
    padding-bottom: 7rem;
}

.title {
    margin-top: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5em;
}

.videos-grid {
    display: grid;
    /* 2 columns small, 4 big */
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    max-width: 700px;
    margin: 0 auto;
}

@media (max-width: 600px) {
    .videos-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

.video-wrapper {
    position: relative;
    padding-top: 177.77%;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.download-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
}

.video-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #202020;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}


.loading, .error {
    text-align: center;
    margin-top: 50px;
}

.cta { 
    position: fixed; 
    bottom: 3rem; 
    z-index: 1;
    right: 30px; 
    left: 30px;
}

.cta .button {
    max-width: 500px;
    margin: 0 auto;
    /* shadow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.spinner {
    border: 8px solid rgba(255, 255, 255, 0.1);
    border-top: 8px solid #FFF;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin-bottom: 12px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error {
    text-align: center;
    color: red;
}
