.checkout {
    max-width: 500px;
    margin: 0 auto;
    padding: 0rem 2rem 5rem;
}


.notice {
    color: rgb(148, 148, 168);
    font-size: 0.9em;
    margin: 0;
    font-weight: 400;
}

.checkout h1 {
    font-size: 2.5rem !important;
    margin: 0;
    margin-bottom: 12px;
    font-weight: 600;
    padding-top: 4rem;
}

h2 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 700;
    letter-spacing: 1px;
}

.plans {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    padding: 1.5rem 0;
}


.plan {
    background: #202020;
    color: #FFF;
    padding: 1rem;
    border-radius: 12px;
    margin: 0.5rem 0;
    cursor: pointer;
    font-size: 1.25em;
    border: none;
    width: 100%;
    align-items: center;

    display: flex;
}

.plan .plan-content {
    padding: 0 0.8rem;
}

.plan .price {
    font-size: 0.9em;
    text-align: right;
    font-family: sans-serif;
    font-weight: 600;
}

.plan-title {
    margin-bottom: 4px;
}

.plan-desc {
    font-size: 0.7em;
    margin: 0;
    font-weight: 400;
    color: #CCD;
    line-height: 1.2;
}

.plan.active {
    background: #2A2A2F;
    transform: scale(1.05);
}

.checkbox {
    display: flex;
    align-items: center;
    background: #222;
    padding-right: 0.5rem;
}


.checkout-form {
    margin-bottom: 2.5rem;
    width: 100%;
}

.checkout-form p {
    margin-bottom: 8px;
    color: #CCD;
}

.email {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.email-form h2 {
    margin-bottom: 1rem;
    font-size: 2.5em;
    font-weight: 700;

}

.email-form input {
    border: none;
    background: #333;
    color: #FFF;

    border-radius: 32px;
    margin-bottom: 0.5rem;
    padding: 1rem 1.4rem;
    width: 100%;

    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    font-family: "Unlockt", sans-serif;

}

.email-form input::placeholder {
    color: #888;
    font-size: 1.2rem;
}

/* no outline */
.email-form input:focus {
    outline: none;
}

.email-form button {

    border: none;
    background: #FF007A;
    color: #FFF;
    cursor: pointer;
    width: 100%;

    border-radius: 32px;
    padding: 1rem 1.4rem;

    font-size: 1.5rem;
    font-family: "Unlockt", sans-serif;
    /*transition: background 0.3s;*/
}

/* disabled */
.email-form button:disabled {
    background: #888;
    cursor: not-allowed;
}


.card-payment {
    padding: 12px 18px;
    border: 2px solid #51ff9f;
    border-radius: 13px;
    background: #12291c;
    margin-bottom: 12px;
}

.spinner-checkout {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top: 4px solid #000;
    width: 19px;
    height: 19px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonials {
    margin-left: -2rem;
    margin-right: -2rem;
}

.testimonials h2 {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
}

.testimonial-marquee {
    display: flex;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.testimonials .testimonial {
    padding: 0.5rem;
}

.testimonials img {
    height: 120px;
    width: auto;
    display: block;
    border-radius: 1rem;
}