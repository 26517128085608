.upload h3 {
    margin-bottom: 2px;
}

.upload .description {
    font-size: 0.9em;
    margin-bottom: 16px;
    color: #aab;
}


.examples {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #000;
    color: #fff;
    width: 100%;
    /* responsive background height % */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* no overflow */
    overflow: hidden;
}

.good-examples,
.bad-examples {
    text-align: left;
    padding: 12px 0;
    width: 100%;
}

.good-examples {
    margin-top: 24px;
}

.bad-examples {
    margin-bottom: 24px;
}


.button-validate {
    padding: 12px 24px;
    font-size: 1.5em;
    font-weight: bold;
    background: #000;
    color: #FFF;
    cursor: pointer;
    border: none;
    border-radius: 32px;
    width: 100%;
    max-width: 500px;
}

.button-change {
    color: black;
    margin-top: 12px;
    font-weight: bold;
}


.preview-container.uploading::before {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: rgb(81, 255, 160);
    box-shadow: 0 0 80px 30px rgb(81, 255, 160);
    clip-path: inset(0);
    top: 0%;
    /*opacity: 0;*/
    animation: x 2s ease-in-out 1s infinite, y 2s ease-in-out 1s infinite;
    /*fadeIn 0s 0.6s infinite,  y 1.5s ease-in-out 0.7s infinite*/
}


@media (min-width: 768px) {

    .good-examples,
    .bad-examples {
        max-width: 70%;
    }
}



.preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.preview {
    max-width: 100%;
    max-height: 80%;
    object-fit: contain;
    border-radius: 32px;
}



@keyframes fadeIn {
    100% {
        opacity: 1;
    }
}

@keyframes x {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-100%);
        top: 100%;
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes y {
    0% {
        clip-path: inset(0);
    }

    33% {
        clip-path: inset(-100px 0 0 0);
    }

    50% {
        clip-path: inset(0 0 0 0);
    }

    83% {
        clip-path: inset(0 0 -100px 0);
        opacity: 1;
    }

    100% {
        clip-path: inset(0);
        opacity: 0;
    }
}