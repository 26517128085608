* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --vh: 1vh;
}

/* load Unlockt-Bold & Unlockt-Regular with otf */
@font-face {
    font-family: 'Unlockt';
    src: url('/public/Unlockt-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Unlockt';
    src: url('/public/Unlockt-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

body {
    font-family: 'Unlockt', 'Roboto', sans-serif;
    background-color: #000;
    color: #fff;
    /*overflow: hidden;*/
}

h1 {
    color: white;
    font-size: 2.2em;
    margin: 0;
    margin-bottom: 12px;
    font-weight: 600;
}

h2 {
    color: white;
    font-size: 2em;
    margin: 0;
    font-weight: 700;
}

h3 {
    color: white;
    font-size: 1.2em;
    margin: 0;
    font-weight: 400;
}


.page {
    display: flex;
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
}

.headline {
    flex: 0 0 auto;
    text-align: center;
    padding-top: 10vh;
}

.headline h1 {
    margin: 24px 0 0;
    font-size: 2.2em;
    font-weight: 600;
}

.middle {
    flex: 1 1 auto;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    width: 100%;
}

@media (min-width: 768px) {
    .middle {
        max-height: 70vh;
    }
}

.bottom {
    flex: 0 0 auto;
    text-align: center;
    padding-bottom: 8vh;
    padding-left: 32px;
    padding-right: 32px;
}

.button {

    font-family: 'Unlockt', 'Roboto', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    color: #000;

    width: 100%;
    padding: 12px 24px;
    background: rgb(81, 255, 160);
    cursor: pointer;

    border: none;
    border-radius: 32px;

    animation: pulse 0.7s infinite;
}


@keyframes pulse {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

}

